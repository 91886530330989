import React from "react";
import * as PropTypes from "prop-types";
import { makeStyles, Typography, withStyles } from "@material-ui/core";
import SEO from "../components/seo";

const useStyles = makeStyles(() => ({
  block: {
    display: "flex",
    flexDirection: "column",
    margin: "0 auto",
    marginBottom: 16,
    maxWidth: 960,
    width: "100%"
  },
  paddingAll: {
    padding: "70px 15px"
  },
  root: {
    flex: 1
  }
}));

function Terms() {
  const classes = useStyles();

  return (
    <>
      <SEO title={"Datenschutzbestimmungen"} />
      <div className={classes.paddingAll}>
        <div className={classes.block}>
          <Typography variant="title">
            Vollkorn Games Datenschutzbestimmungen
          </Typography>
          <Typography variant="body1">
            Zuletzt geändert 17. Oktober 2018
          </Typography>
        </div>
        <div className={classes.block}>
          <Typography variant="body2">Datenschutzbestimmungen</Typography>
          <Typography variant="body1">
            Wir erheben, verwenden und Speichern Ihre personenbezogenen Daten
            ausschließlich im Rahmen der Bestimmungen des
            Bundesdatenschutzgesetzes der Bundesrepublik Deutschland.
            Nachfolgend unterrichten wir Sie über Art, Umfang und Zweck der
            Datenerhebung und Verwendung.
          </Typography>
        </div>
        <div className={classes.block}>
          <Typography variant="body2">
            Erhebung und Verarbeitung von Daten
          </Typography>
          <Typography variant="body1">
            Folgende Daten verarbeiten wir im Rahmen der Nutzung der Vollkorn
            Games Apps bzw. der Website:
            <br />
            <br />
            - Für die Website werden Protokolldatei-Informationen erhoben, also
            Daten die vom Browser des Nutzers bei jedem Aufruf automatisch zur
            Verfügung gestellt werden, wenn der Browser auf die Website. Diese
            Protokolldaten beinhalten beispielsweise die Art und Einstellungen
            des Browsers, die anfragende IP-Adresse, sowie Datum und Zeitpunkt
            der Abfrage.
            <br />- Für unseren E-Mail-Kontakt mit der E-Mail-Adresse
            <a href="mailto:info@vollkorn.games"> info@vollkorn.games </a>{" "}
            verwenden wir hintergründig Google Mail, somit werden dort
            automatisch von Google Ihre E-Mail-Adresse, sowieso mögliche Inhalte
            und Anhänge verarbeitet.
            <br />
            - In der Spiele-App Auguste können private Bilder vom Mobilgerät in
            die Spiele-App lokal geladen und lokal gespeichert werden. Diese
            werden ausschließlich von der Spiele-App auf dem Gerät lokal genutzt
            auf dem die Spiele-App installiert ist und nicht von Vollkorn Games
            online verarbeitet.
            <br />
          </Typography>
        </div>
        <div className={classes.block}>
          <Typography variant="body2">Nutzung der Daten</Typography>
          <Typography variant="body1">
            - Schreiben Sie uns eine E-Mail über unsere Kontakt-E-Mail-Adresse
            <a href="mailto:info@vollkorn.games"> info@vollkorn.games </a>, so
            werden wir gegebenenfalls Ihre E-Mail-Adresse für eine Antwort
            nutzen.
            <br />
            - Die in der Spiele-App Auguste verarbeiteten Bilder werden nur auf
            dem jeweiligen Mobilgerät gespeichert und nicht weiter genutzt.
            <br />
            <br />
            Wie und an wen werden die Daten weitergegeben?
            <br />
            <br />
            Schreiben Sie uns eine E-Mail über unsere Kontakt-E-Mail-Adresse
            <a href="mailto:info@vollkorn.games"> info@vollkorn.games </a>, so
            werden automatisiert Ihre E-Mail-Adresse, sowie mögliche Inhalte und
            Anhänge von Google verarbeitet. Weitere Informationen hierzu lesen
            Sie bitte in der Datenschutzbestimmung von Google unter folgender
            Adresse:
            <a href="https://policies.google.com/privacy?hl=de&gl=de">
              https://policies.google.com/privacy?hl=de&gl=de
            </a>
            <br />
          </Typography>
        </div>
        <div className={classes.block}>
          <Typography variant="body2">Löschung von Daten</Typography>
          <Typography variant="body1">
            Auf elektronische oder schriftliche Anfrage, werden wir Ihre Daten
            so gut wie möglich löschen. Dies betrifft vorrangig den mit uns
            geführten E-Mail-Verkehr, den wir für uns löschen, dort aber
            weiterhin auf die Datenschutzbestimmung von Google verweisen (s. o.
            Nutzung der Daten). Die Anfrage ist an folgende Adresse zu richten
            (s. u. Auskunftsrecht).
            <br />
          </Typography>
        </div>
        <div className={classes.block}>
          <Typography variant="body2">Auskunftsrecht</Typography>
          <Typography variant="body1">
            Auf elektronische oder schriftliche Anfrage informieren wir den
            Nutzer der Website oder der App über die zu seiner Person
            gespeicherten Daten. Die Anfrage ist an folgende Adresse zu richten:
            <br />
            Schriftlich:
            <br />
            Neu, Li, Busch, Becker GbR
            <br />
            Günther-Wagner-Allee 29
            <br />
            30177 Hannover
            <br />
            Elektronisch:
            <br />
            <a href="mailto:info@vollkorn.games">info@vollkorn.games</a>
            <br />
          </Typography>
        </div>
      </div>
    </>
  );
}

export default Terms;
